<template>
    <div>
        <MailSignup  class="mw-500 mx-auto"/>
        <p class="mx-auto mw-500 mb-2 text-center text-secondary">
            Om du vill hålla dig uppdaterad om våra fonder och innehav kan du prenumerera på vårt nyhetsbrev ovan.
        </p>
    </div>
</template>

<script>
import DefaultModule from '@/components/mixins/DefaultModule';

export default {
    mixins:[DefaultModule],
}
</script>