import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Router from "vue-router";
import { BootstrapVue } from 'bootstrap-vue'
import router from "@/router/router";
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
//import VueAnalytics from 'vue-analytics'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

stockInit(Highcharts);
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Router)
Vue.use(Vuex)
Vue.use(HighchartsVue);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

window.addEventListener('scroll', () => {
  document.body.style.setProperty('--scroll',window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
}, false);