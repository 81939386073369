<template>
  <div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-3 d-flex flex-column justify-content-center align-items-center" v-if="!Right">
              <slot name="img"></slot>
            </div>
            <div class="col-12 col-sm-9 d-flex flex-column justify-content-center">
              <slot></slot>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageWidthText',
  props:{
    ImgSrc: String,
    Right: {
      default: false,
      type: Boolean
    }
  }
}
</script>


<style scoped>

</style>