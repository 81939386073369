<template>
    <div>
      <h1 class="text-tin-dark mb-4 hero-heading pl-3">Fokus på innovation</h1>
      <div class="">
        <div class="container">
            <div class="row" >
              <div class="col-12 col-md-6 p-3">
                  <div class="h-100">
                    <div class="h-100 py-5 px-3 p-md-5 chart-card">
                        <h2 class="text-tin-dark text-center mb-5">Några av våra bolag</h2>
                        <div class="d-flex justify-content-center flex-wrap mb-5">
                          <div class="flip-holder m-3">
                            <flip-card :length="16" :offset="2" class="text-center">
                              <div class="logo-card reflection">
                                <img src="/img/logos/embracer.png">

                              </div>
                              <div class="logo-card reflection">
                                <img src="/img/logos/nintendo.png">

                              </div>
                              <div class="logo-card reflection">
                                <img src="/img/logos/adevinta.png">

                              </div>
                              <div class="logo-card reflection">
                                <img src="/img/logos/paradox.png">

                              </div>
                            </flip-card>
                          </div>
                          <div class="flip-holder m-3 d-none d-xl-block">
                            <flip-card :length="16" class="text-center">
                              <div class="logo-card reflection">
                                <img src="/img/logos/adobe.png">

                              </div>
                              <div class="logo-card reflection">
                                <img src="/img/logos/sinch.png">

                              </div>
                              <div class="logo-card reflection">
                                <img src="/img/logos/salesforce.png">

                              </div>
                              <div class="logo-card reflection">
                                <img src="/img/logos/unity.png">

                              </div>
                            </flip-card>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import DefaultModule from '@/components/mixins/DefaultModule'
import Language from '@/components/mixins/Language'
import FlipCard from '../modules/FlipCard.vue'
import { mapGetters, mapActions } from "vuex"

export default {
    mixins: [DefaultModule, Language],
    components: {
        FlipCard
    },
    computed:{
        ...mapGetters(["getData"]),
        apiUrl(){
          return `/public/fund/1/1/`;
        }
    },
    methods:{
       ...mapActions(["fetch"]),
    },
    created(){
      this.fetch({url: this.apiUrl});
    }
}
</script>

<style scoped>
  .flip-holder{
    width: 170px;  
  }
 .logo-card{
    width: 100%;
    background:white;
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
  }
  img{
    width:100%;
    object-fit: contain;
    filter: invert(0%) sepia(0%) saturate(0%) brightness(100%);
    filter: invert(100%) brightness(5%) contrast(100%);
    filter: invert(52%) sepia(9%) saturate(3572%) hue-rotate(316deg) brightness(105%) contrast(95%);
  }
  .kpi-card{
    background:white;
  }
  .chart-card{
    border-radius: 16px;
    overflow: hidden;
    background: white;
  }
  .kpi-fade-heading{
    background: rgb(5,255,198);
    background: linear-gradient(270deg, rgba(5,255,198,1) 0%, rgba(5,84,255,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>