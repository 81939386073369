<template>
    <div class="content-holder">
        <div class="inner-content px-2 px-md-5 pt-5">
            <slot></slot>
        </div>
    </div>
</template>

<script>


export default {
    name:'ContentSection',
}
</script>

<style scoped>
    .inner-content{
        max-width: 1400px;
        margin: 0 auto;
    }
    .odd{
        background:#f5f5f8;
    }
</style>