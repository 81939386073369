<template>
    <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg"  :style="`transform: rotate(${rotate}deg)`" >
      <ellipse ref="ringani" class="brushy" ry="45%" id="svg_1" cy="50%" cx="50%" stroke-width="3" stroke="#fff" fill="transparent"/>
    </svg>
</template>

<script>
import ScrollTriggerVue from '../mixins/ScrollTrigger.vue'
export default {
    mixins: [ScrollTriggerVue],
    data(){
      return{
        FluidTriggers: {
          ringani: {ref: 'ringani', screenPos: 1 }
        }
      }
    },
    props:{
        rotate:{
            type: Number,
            default: 0,
        },
        progress:{
            type: Number,
            default: 0
        }
    },
}
</script>

<style scoped>
    @keyframes brushin {
        from{
            stroke-dashoffset: 340px;
        }
        to {
            stroke-dashoffset: 0px;
        }
    }
    .brushy {
        stroke-dasharray: 340px;
        stroke-dashoffset: 340px;
        animation: brushin 1s ease infinite;
        animation-play-state: paused;
    }
</style>