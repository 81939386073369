<template>
  <div>
        <h1 class="text-tin-dark mb-4 hero-heading pl-3">Vi investerar i Framtiden</h1>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 p-3">
              <div class="px-4 py-5 sector-1 sector-card h-100">
                <image-with-text>
                  <template v-slot:img>
                    <round-ball-text  class="rbt-1 p-1" style="width:120px; height:120px;" >
                      <img src="/img/icons/software.svg" class="w-100 m-2 tint-white">
                    </round-ball-text>
                  </template>
                  <h2 class="mb-1 text-white mb-0">Mjukvara</h2>
                  <p class="text-white">Mjukvara äter världen. Vi försöker hitta bolag som är nichevinnare eller tack vare starka produkter eller tjänster kan uppvisa hög tillväxt och hög lönsamhet över tid.</p>
                </image-with-text>
              </div>
            </div>
            <div class="col-12 col-md-6 p-3">
                <div class="px-4 py-5 sector-2 sector-card h-100">
                  <image-with-text>
                    <template v-slot:img>
                        <round-ball-text :rotate="90" class="rbt-2 p-1" style="width:120px; height:120px;" >
                           <img src="/img/icons/health.svg" class="w-100 tint-white">
                        </round-ball-text>
                    </template>
                    <h2 class="mb-1 text-white">Hälsa</h2>
                      <p class="text-white mb-0">
                        Medicinteknik är ett måste i ett samhälle där vi vill leva längre och friskare. Vi försöker hitta bolag som genom ett patent eller teknisk innovation kan uppvisa både hög tillväxt och hög lönsamhet.
                      </p>
                  </image-with-text>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="px-4 py-5 sector-3 sector-card">
                  <image-with-text class="mw-600 ">
                    <template v-slot:img>
                        <round-ball-text :rotate="270" class="rbt-3 p-1" style="width:120px; height:120px;" >
                           <img src="/img/icons/gaming.svg" class="w-100 tint-white">
                        </round-ball-text>
                    </template>
                    <h2 class="mb-1 text-white">Digitala varumärken</h2>
                    <p class="text-white mb-0">
                      Fortnite, Minecraft och Battlefield är globala exempel på hur digitala varumärken och digitala världar kommer bli än viktigare i framtiden.
                    </p>
                  </image-with-text>
                </div>
            </div>
          </div>
        </div>

  </div>
</template>

<script>
import ImageWithText from '@/components/modules/ImageWithText'
import RoundBallText from '@/components/modules/RoundBallText'
import Language from '@/components/mixins/Language'
export default {
  mixins: [Language],
  components: {
    ImageWithText,
    RoundBallText
  }
}
</script>


<style>
  div.container{
    max-width: 100%;
  }
  .scroll-holder{
    height: 1500px;
  }
  .scroll-card{
      position: -webkit-sticky;
      position: sticky;
      height: auto;
      top:120px;
  }
  .sector-card{
    border-radius: 16px;
    color:white;
  }
  .sector-1{
    background: rgb(59,144,113);
    background: linear-gradient(0deg, rgba(59,144,113,1) 0%, rgba(125,219,167,1) 100%);
  }
  .sector-2{
    background: rgb(47,139,168);
    background: linear-gradient(0deg, rgba(47,139,168,1) 0%, rgba(148,164,218,1) 100%);
  }
  .sector-3{
    background: rgb(251,135,98);
    background: linear-gradient(0deg, rgba(240,124,87,1) 0%, rgba(246,202,134,1) 100%);
  }

  .rbt-1 path.brushy {
    stroke: #467d9c;
  }
  .rbt-2 path.brushy {
    stroke: #589baf;
  }
  .rbt-3 path.brushy {
    stroke: #73b5c2;
  }

  .svg-img{
    width:100%;
    
  }
</style>