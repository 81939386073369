<template>
  <div>
    <NavBar />
    <full-image>
      <DownArrow href="#first-section" class="d-none d-sm-block"/>
      <div class="w-100 h-100 d-flex justify-content-center align-items-center p-2">
        <div class="d-flex w-100 centercard justify-content-center">
            <div class="row magic-shadow w-100">
              <div class="col-12 col-md-6 d-flex flex-column justify-content-center p-3">
                <you-tube-video class="shadow-lg">
                  <div class="d-none d-md-flex mt-2 justify-content-center position-absolute" style="bottom:0;top:100%;">
                    <img height="55" src="/img/graphics/arrow.svg" class="video-arrow">
                    <div class="text-white line-height-sm video-arrow-text position-relative mb-0" style="top:22px;left:-20px;">
                      Förvaltarna Carl och Erik berättar om vår fond TIN Ny Teknik och vilka sektorer vi gillar att investera i.
                    </div>
                  </div>
                </you-tube-video>

              </div>
              <div class="col-12 col-md-6 d-flex flex-column justify-content-center p-3">
                <h2 class="text-white"><span class="tin-bullet bg-tin-highlight-3"></span>Kraften av Teknik och Hälsa.</h2>
                <div class="line-height-sm text-white mb-3">
                  TIN Ny Teknik är en aktiefond som investerar i teknik, hälsa och digitala varumärken. Det är en fond för dig som tror på innovation, småbolag och Norden.
                </div>
                <Button href="#first-section" text="Läs mer" class="btn-light flex-grow-0"/>
              </div>
            </div>
        </div>
      </div>
    </full-image>

    <!-- Anchor point -->
    <div id="first-section"></div>

    <content-section-narrow class="odd pb-0">
      <BuyFundLite />
    </content-section-narrow>


    <content-section class="odd">
      <AboutTin />
    </content-section>

    <content-section class="odd">
      <BuyFund />
    </content-section>

    <content-section class="odd">
      <Sectors />
    </content-section>

    <content-section class="odd">
      <Development />
    </content-section>

    <content-section class="even shadow bg-tin">
      <Footer />
    </content-section>
  </div>
</template>

<script>
import DefaultPage from '@/components/mixins/DefaultPage'
import Language from '@/components/mixins/Language'
import NavBar from '@/components/partial/NavBar'
export default {
  name: 'Landing1',
  mixins: [DefaultPage, Language],
  components: {NavBar}
}
</script>


<style scoped>

  .centercard{
    max-width:1000px;
  }
  .video-arrow{
    position: relative;
    left:-20px;
    top:5px;
    transform: rotate(15deg);
  }
  .video-arrow-text{
    position: relative;
    top:3px;
    width:70%;
    font-size:1rem;
  }

  #first-section{
    position: relative;
    top:-64px;
  }

</style>
