export default class ABTest {
    static OPTIONS = {
        bg: {
            options: {
                '1': 'bg/bg2.jpg',
                '2': 'bg/bg3.jpg',
                '3': 'bg/bg4.jpg',
                '4': 'bg/bg5.jpg',
                '5': 'bg/bg6.jpg',
                '6': 'bg/bg7.jpg',
                '7': 'bg/bg8.jpg',
                '8': 'bg/bg9.jpg',
                '9': 'bg/bg10.jpg',
            },
            default: "9"
        },
        lo: {
            options:{
                '1': 'Landing1',
                '2': 'Landing2',
                '3': 'Landing3',
            },
            default: '1'
        },
        text: {
            options: {
                "0": "#0b4d60",
                "1": "rgb(8, 50, 64)"
            },
            default: '0'
        },
    }

    static getOptionValue(component, option) {
        let option_key = component.$route.query[option];
        let option_value = '';
        if (option_key){
            option_value = this.OPTIONS[option].options[option_key];
        } else {
            
            let dflt = this.OPTIONS[option].default;
            option_value = this.OPTIONS[option].options[dflt];
        }
        return option_value;
    }
}
