<template>
    <div class="video-container">
        <iframe ref="iframeContent" width="1000" height="1000" :src="`https://www.youtube.com/embed/djcyTJmEtQ8?autoplay=0&amp;mute=1`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <slot></slot>
    </div>
</template>

<script>
import DefaultModule from '../mixins/DefaultModule.vue'

export default {
    mixins:[DefaultModule]    
}
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius:16px;
}

</style>
