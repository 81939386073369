<template>
    <div class="card-holder" ref="cardholder">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props:{
        length:{
            type: Number,
            default: 10
        },
        offset:{
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            num_cards: 0
        }
    },
    mounted(){
        this.num_cards = this.$refs.cardholder.childElementCount;
        for(const [i, child] of this.$refs.cardholder.childNodes.entries()){
            child.setAttribute("style",
            `animation-delay:-${this.offset + i * (this.length/this.num_cards)}s;
            animation-duration:${this.length}s;`);
        }
    }
}
</script>

<style scoped>
  .card-holder{
    perspective: 1900px;
    display: flex;
  }
  .card-holder > * {
    flex-shrink: 0;
    width: 100%;
    opacity: 0;
    animation-iteration-count: infinite;
    transform-style: preserve-3d;
  }

  .card-holder > *:not(:first-child){
    margin-left: -100%;
  }

  .card-holder > *:first-child:nth-last-child(2),
  .card-holder > *:first-child:nth-last-child(2) ~ * {
    animation-name: display-2;
  }
  .card-holder > *:first-child:nth-last-child(3),
  .card-holder > *:first-child:nth-last-child(3) ~ * {
    animation-name: display-3;
  }
  .card-holder > *:first-child:nth-last-child(4),
  .card-holder > *:first-child:nth-last-child(4) ~ * {
    animation-name: display-4;
  }

  @keyframes display-2 {
    0%{
      opacity: 0;
      transform: rotateX( 90deg );
    }
    1% {
      opacity: 1;
      transform: rotateX( 0deg );
    }
    49%{
      opacity: 1;
      transform: rotateX( 0deg );
    }
    50%{
      opacity: 0;
      transform: rotateX( -90deg );
    }
    100%{
      opacity: 0;
      transform: rotateX( 0deg );
    }
  }
  @keyframes display-3 {
    0%{
      opacity: 0;
      transform: rotateX( 90deg );
    }
    1% {
      opacity: 1;
      transform: rotateX( 0deg );
    }
    32.33%{
      opacity: 1;
      transform: rotateX( 0deg );
    }
    33.33%{
      opacity: 0;
      transform: rotateX( -90deg );
    }
    100%{
      opacity: 0;
      transform: rotateX( 0deg );
    }
  }
  @keyframes display-4 {
    0%{
      opacity: 0;
      transform: rotateX( 90deg );
    }
    1% {
      opacity: 1;
      transform: rotateX( 0deg );
    }
    24%{
      opacity: 1;
      transform: rotateX( 0deg );
    }
    25%{
      opacity: 0;
      transform: rotateX( -90deg );
    }
    100%{
      opacity: 0;
      transform: rotateX( 0deg );
    }
  }
</style>