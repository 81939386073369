<template>
    <div class="p-relative" ref="parallax">
        <div class="img-effect"></div>
        <div class="img-overlay">
            <slot></slot>
        </div>
        <img class="img-full" :src="'/img/' + ABTestValue">
    </div>
</template>

<script>
import ABTest from '@/abtest'

export default {
    computed:{
        ABTestValue(){
            return ABTest.getOptionValue(this, 'bg')
        },
    },
}
</script>


<style scoped>
    .img-effect{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.0);
        -webkit-box-shadow: inset 0px 0px 31px 0px rgba(0,0,0,0.44);
        -moz-box-shadow: inset 0px 0px 31px 0px rgba(0,0,0,0.44);
        box-shadow: inset 0px 0px 31px 0px rgba(0,0,0,0.44);
    }
    .img-overlay{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
    }
    .img-full{
        width:100%;
        height:100vh;
        object-fit: cover;

    }
</style>