<template>
    <a role="button" class="btn">
        {{text}}
    </a>
</template>

<script>
export default {
    name:'Button',
    props:{
        text: String,
    }
}
</script>

<style scoped>
    a{
        display:inline-block;
    }
    .btn{
        border-radius:100px;
    }
</style>