<template>
    <div class="ball position-relative">
        <div class="ball-bg">
            <RingEffect :rotate="rotate"/>
        </div>
        <div class="ball-content d-flex justify-content-center align-items-center ">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import RingEffect from '@/components/modules/RingEffect'
export default {
    props:{
        rotate:{
            type: Number,
            default: 0
        },
    },
    components:{
        RingEffect
    },
}
</script>

<style>
    .ball-img-holder{
        align-content: center;
    }
    .ball-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .ball{
        width:100%;
        height:100%;
        color:white;
        text-align: center;
        overflow: hidden;
        z-index: 1;
    }
    .ball-bg{
        position: absolute;
        left: 0;
        right:0;
        top:0;
        bottom:0;
        z-index:-1;
    }
</style>