<template>
    <nav class="navbar navbar-dark p-0" >
        <div class="nav-fill w-100 h-100 position-absolute"></div>
        <div class="nav-content w-100 d-flex">
            <a class="navbar-brand" href="https://tinfonder.se/">
                <img src="/img/header_logo.png" class="d-inline-block align-top" alt="">
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            scrollTop: true
        }
    },
    methods: {
        handleScroll() {
            this.scrollTop = window.scrollY === 0;
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
    .navbar{
        position: absolute;
        left:0;
        right: 0;
    }
    .nav-content{
        padding:12px 10px 12px 20px;
        z-index:2;
    }
    .nav-fill{
        -webkit-transition: all 0.12s linear;
        -moz-transition: all 0.12s linear;
        -o-transition: all 0.12s linear;
        transition: all 0.12s linear;
    }
    .transparent{
        background: rgba(0,0,0,0);
        transform: translateY(-100%);
    }
</style>