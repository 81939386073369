<template>
    <div>
      <h1 class="text-tin-dark hero-heading pl-3">En unik<br><span class="fade-heading" ref="fadehead">teknikfond</span></h1>

      <div class="mx-auto mb-4">
        <div class="container">
          <div class="row">

             <div class="col-12 col-md-6 p-3">
               <flip-card :length="20" :offset="5" class="h-100">
                  <div class="tin-card text-wrap custom-card px-4 py-5 text-center h-100">
                    <h2 class="text-tin-dark"><i class="tin-bullet bg-tin-highlight-1"></i>Teknisk innovation</h2>
                    <p class="mb-0 px-3 px-lg-5">TIN Ny Teknik är en aktiefond som investerar i teknik, hälsa och digitala varumärken.</p>
                  </div>
                  <div class="tin-card text-wrap custom-card px-4 py-5  text-center h-100">
                    <h2 class="text-tin-dark"><i class="tin-bullet bg-tin-purple-1"></i>Våra kunder</h2>
                    <p class="mb-0 px-3 px-lg-5">Över 350 000 privatpersoner har investerat i våra fonder och största kundgrupp är åldern 18-30 år.</p>
                  </div>
               </flip-card>
            </div>

            <div class="col-12 col-md-6 p-3">
              <flip-card :length="20" class="h-100">
                  <div class="tin-card text-wrap custom-card px-4 py-5  text-center">
                    <h2 class="text-tin-dark"><i class="tin-bullet bg-tin-highlight-3"></i>Digitalt fokus</h2>
                    <p class="mb-0 px-3 px-lg-5">Vi lägger vårt fokus på digitala snabbväxande bolag.</p>
                  </div>
                  <div class="tin-card text-wrap custom-card px-4 py-5  text-center">
                    <h2 class="text-tin-dark"><i class="tin-bullet bg-tin-red-5"></i>TIN Analytics</h2>
                    <p class="mb-0 px-3 px-lg-5">Vi samlar in över 600 000 datapunkter om våra innehav varje dag för att kunna följa deras utveckling bättre.</p>
                  </div>
                  <div class="tin-card text-wrap custom-card px-4 py-5 text-center">
                    <h2 class="text-tin-dark"><i class="tin-bullet bg-tin-green-dark"></i>Påverkan</h2>
                    <p class="mb-0 px-3 px-lg-5">Vi är aktiva ägare i fondens innehav och vill påverka bolagen att fortsätta i rätt riktning.</p>
                  </div>
              </flip-card>
            </div>

          </div>
          <div class="row">
            <div class="col-12 pt-3 px-3">
              <div class="custom-card buy-card px-4 py-5 ">
                <h1 class="text-tin-dark mb-0 text-center">
                  Investera i TIN Ny Teknik
                </h1>
                <p class="text-center mb-2">Följ med oss på fondens resa in i framtiden.</p>
                <div class="d-flex justify-content-between flex-wrap mw-800 mx-auto">
                    <div v-for="btn in Buttons" :key="btn.cta" class="flex-grow-1 p-3">
                      <a 
                        role="button" 
                        :id="btn.cta" 
                        :href="btn.href" 
                        class="btn btn-dark btn-buy py-3 px-5 btn-block"
                        onclick="convert()"
                      >
                        <img :src="btn.imgsrc" height="28">
                      </a>
                    </div>
                </div>
                <p class="mw-500 text-center mx-auto mb-0">
              Mer information inklusive fondens avgift finns i fondens faktablad och prospekt. Dessa finns att hämta på <a href="https://tinfonder.se/">tinfonder.se</a>
            </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import FlipCard from '@/components/modules/FlipCard'
import ScrollTrigger from '@/components/mixins/ScrollTrigger'
import Language from '@/components/mixins/Language'

export default {
  mixins: [ScrollTrigger, Language],
  components:{
    FlipCard
  },
  props: {
    FundClassId: Number
  },
  data(){
    return {
      Buttons: [
        { cta: "avanza", href: "https://www.avanza.se/fonder/om-fonden.html/927735/tin-ny-teknik-a", imgsrc: "/img/avanza.svg" },
        { cta: "nordnet", href: "https://www.nordnet.se/marknaden/fondlistor/16994475-tin-ny-teknik", imgsrc: "/img/nordnet.svg" },
        { cta: "seb", href: "https://seb.se/bors-och-finans/fonder/fondlista#/funds/detail/4500/0P0001FLH9?back=true", imgsrc: "/img/seb.svg" },
      ],
      FluidTriggers:{
        fadehead: {ref: 'fadehead', screenPos: 1}
      },
      funds: {
        2:{
          title: {
            'se': 'Investera i TIN Ny Teknik',
            'no': 'Invester i TIN Ny Teknik',
          },
          box1: {
            text: "TIN Ny Teknik är en aktiefond som investerar i teknik, hälsa och digitala varumärken.",
            heading: 'Teknisk innovation'
          },
          buttons: [
            {imgsrc: '/img/avanza.svg', cta: 'ava-tnt-visit', href: "https://www.avanza.se/fonder/om-fonden.html/927735/tin-ny-teknik-a"},
            {imgsrc: '/img/nordnet.svg', cta: 'non-tnt-visit', href: "https://www.nordnet.se/marknaden/fondlistor/16994475-tin-ny-teknik"},
            {imgsrc: '/img/seb.svg', cta: 'seb-tnt-visit', href: "https://seb.se/bors-och-finans/fonder/fondlista#/funds/detail/4500/0P0001FLH9"}
          ]
        },
        6:{
          title: {
            'se': 'Investera i TIN Ny Teknik',
            'no': 'Invester i TIN Ny Teknik',
          },
          box1: {
            text: "TIN Ny Teknik är en aktiefond som investerar i teknik, hälsa och digitala varumärken.",
            heading: 'Teknisk innovation'
          },
          buttons: [
            {imgsrc: '/img/dnb.svg', cta: 'dnb-tnt-visit', href: "https://www.dnb.no/"},
            {imgsrc: '/img/nordnet.svg', cta: 'non-tnt-visit', href: "https://www.nordnet.no/market/funds/17273229-tin-ny-teknik"},
          ]
        },
        8:{
          title: {
            'se': 'Investera i Avanza World Tech by TIN',
            'no': 'Invester i Avanza World Tech by TIN',
          },
          box1: {
            text: "Avanza World Tech by TIN, ett samarbete mellan årets nykomling och årets bank.",
            heading: 'Ett unikt samarbete'
          },
          buttons: [
            {imgsrc: '/img/avanza.svg', cta: 'ava-twt-visit', href: "https://www.avanza.se/fonder/om-fonden.html/1102641/avanza-world-tech-by-tin"},
          ]
        }
      }
    }
  },
  methods: {
    convert() {
        fetch('https://tinfonder.se/api/convert/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: 'purchase',
            })
        })
    }
  },
}
</script>

<style scoped>
  .container{
    max-width: 100%;
  }

  .custom-card{
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.03);
    border-radius: 16px;
  }

  .buy-card{
    
  }

  .btn-buy{
    background:#57ac8f;
    border-color:#57ac8f;
    border-radius:100px;
  }
  .btn-buy:hover{
    background:#3e9285;
    border-color:#3e9285;
  }
  .btn-buy-2{
    background:#336c7f;
    border-color:#336c7f;
  }
</style>