<template>
  <div id="app">
    <Popup />
    <router-view></router-view>
  </div>
</template>

<script>
import ABTestModule from './components/mixins/ABTestModule'
import Popup from './components/modules/Popup.vue';
export default {
  name: 'App',
  components:{
    Popup
  },
  mixins: [ABTestModule],
  mounted(){
    document.body.style.setProperty('--text-tin-dark', this.ABTestValue('text'));
  }
}
</script>

<style>
:root {
  --tin-blue-1: #2f607e;
  --tin-blue-2: #437e9a;
  --tin-blue-3: #437e9a;
  --tin-blue-4: #74b4c1;
  --tin-blue-5: #90cbd0;
  --tin-blue-6: #b2e0df;
  --tin-blue-7: #d7f1ed;

  --tin-red-1: #f15864;
  --tin-red-2: #f86e61;
  --tin-red-3: #fb8762;
  --tin-red-4: #f89f68;
  --tin-red-5: #f4b675;
  --tin-red-6: #f1cb8a;
  --tin-red-7: #f1cb8a;

  --tin-green-1: #20596b;
  --tin-green-2: #2a7779;
  --tin-green-3: #3d9184;
  --tin-green-4: #57ac8e;
  --tin-green-5: #78c49a;
  --tin-green-6: #a1d8ae;
  --tin-green-7: #cbeaca;
}
.highcharts-credits{
  display:none;
}
html, body{
  overflow-x: hidden;
}
a {
  color:rgb(105, 171, 214);
}
@font-face {
  font-family: GTWalsheimBold;
  src: url('/fonts/GT-Walsheim-Pro-Bold.ttf');
}
@font-face {
  font-family: GTWalsheim;
    src: url('/fonts/GT-Walsheim-Pro-Regular.ttf');
}
body > *{
  font-family: GTWalsheim;
}
div.container{
  padding: 0;
}
.tincard{
    border-radius: 20px;
    background:#f5f5f5;
    color:rgb(29, 29, 31);
    width: 100%;
    padding: 20px
}
.bg-tin{
  background: #6a94a0;
}
.bg-tin-secondary{
  background:#f5f5f5;
}
.bg-tin-green{
  background: #67b79c;
}
.bg-tin-green-2{
  background: #3c9286;
}
.bg-tin-green-dark{
  background: #53ab8e;
}
.bg-tin-light-blue{
  background:#5d95a3;
}
.bg-tin-highlight-1{
  background: #467d9c;
}
.bg-tin-highlight-2{
  background: #589baf;
}
.bg-tin-highlight-3{
  background: #73b5c2;
}
.bg-tin-highlight-4{
  background: #b2dfde;
}
.bg-tin-red-1{
  background: #f05765;
}
.bg-tin-red-2{
  background: #f76f61;
}
.bg-tin-red-3{
  background: #fa8762;
}
.bg-tin-red-4{
  background: #fa8762;
}
.bg-tin-red-5{
  background: #f5b675;
}

.bg-tin-purple-1{
  background: #CF64A0;
}

p{
  color: rgb(71, 71, 71);
  color:black;
  color: rgb(31, 31, 31);
  font-size: 19px;
}
p.small{
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6{
  font-family: GTWalsheimBold;
}
.hero-heading{
    font-size: 3.8rem!important;
}
@media (max-width: 768px){
  .hero-heading{
    font-size: 2.6rem!important;
  }
}
.hero-heading-2{
    font-size: 2.8rem!important;
}

.text-tin-primary{
  color:#6a94a0;
}
.text-tin-primary-light{
  color:#d5e4e8;
}
.text-tin-green{
  color: #7ea88c;
}
.text-tin-green-light{
  color: #cfe8d7;
}
.text-tin-green-dark{
  color: #658c72;
}
.text-tin-dark{
  /*color:#0b4d60;
  color: black;
  color:rgb(10,10,10);
  color:#0c161f;
  color: rgb(8, 30, 37);
  color: rgb(40, 60, 80);
  color: rgb(8, 50, 64);*/
  color: var(--text-tin-dark)
}
.text-tin-light-blue{
  color:#5d95a3;
}
.text-tin-secondary{
  color: rgb(71, 71, 71);;
}
.text-tin-highlight-1{
  color: #467d9c;
}
.text-tin-highlight-2{
  color: #589baf;
}
.text-tin-highlight-3{
  color: #73b5c2;
}
.text-tin-highlight-4{
  color: #b2dfde;
}

.mw-400{
  max-width:400px;
}
.mw-500{
  max-width:500px;
}
.mw-600{
  max-width:600px;
}
.mw-700{
  max-width:700px;
}
.mw-800{
  max-width:800px;
}
.line-height-sm{
  line-height: 1.2rem;
}
.rounded-overflow-lg{
  border-radius:16px;
  overflow: hidden;
}
.magic-shadow::before{
  position: absolute;
  top:50%;
  left:20%;
  right:20%;
  height:1px;
  background:rgba(0,0,0,0.4);
  content: '';
  -webkit-box-shadow: 0 0 185px 185px rgba(0,0,0,0.4);
  -moz-box-shadow: 0 0 185px 185px rgba(0,0,0,0.4);
  box-shadow: 0 0 185px 185px rgba(0,0,0,0.4);
}
.btn.btn-outline-tin-dark{
  border:2px solid rgb(71, 71, 71);
}
.reflection{
    position: relative;
}
.reflection::before{
    height:35%;
    content: '';
    z-index:10;
    display: block;
    position: absolute;
    left:0;
    right:0;
    bottom:-45%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background: linear-gradient(to bottom, rgba(184, 176, 154, 0.10), rgba(0,0,0,0));
}
.tin-bullet{
  width: 0.9rem;
  height: 1.5rem;
  display: inline-block;
  margin-right: 0.3rem;
}

@keyframes transgradient {
  to {
    background-position: top center;
  }
}

.fade-heading{
  background: rgb(255,182,90);
  background: linear-gradient(0deg, rgba(255,182,90,1) 0%, rgba(255,173,188,1) 50%, rgba(255,61,79,1) 100%);
  background-size: 200% 300%;
  background-position: bottom center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: transgradient 1s linear infinite;
  animation-play-state: paused;
}
.fade-heading-2{
background: rgb(5,255,198);
background: linear-gradient(0deg, rgba(5,255,198,1) 0%, rgba(5,84,255,1) 100%);
  background-size: 200% 500%;
  background-position: bottom center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: transgradient 1s linear infinite;
  animation-play-state: paused;
}
.btn.btn-white{
  background:#0554ff;
  color:white;
  border-radius: 100px;
}
</style>
