import { render, staticRenderFns } from "./RingEffect.vue?vue&type=template&id=5e344fac&scoped=true&"
import script from "./RingEffect.vue?vue&type=script&lang=js&"
export * from "./RingEffect.vue?vue&type=script&lang=js&"
import style0 from "./RingEffect.vue?vue&type=style&index=0&id=5e344fac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e344fac",
  null
  
)

export default component.exports