<template>
    <p class="mx-auto mw-600 mb-4 text-center text-white">
        <img src="/img/header_logo.png" class="mb-4">
        <br>
          <a class="text-white" href="https://tinfonder.se/om-tin/#legal">Legal</a><b class="mx-2">|</b> 
          <a class="text-white" href="https://tinfonder.se/om-tin/#privacy">Privacy</a><b class="mx-2">|</b> 
          <a class="text-white" href="https://tinfonder.se/om-tin/#cookies">Cookies</a>
        <br>
        <br>
          <small>
            Historisk avkastning är inte någon garanti för framtida avkastning. De pengar du investerar i fonder kan både öka och minska i värde och det är inte säkert att du får tillbaka hela det insatta kapitalet. Faktablad, informationsbroschyrer och fondbestämmelser för TIN Fonders fonder finns att hämta på vår hemsida eller hos någon av våra återförsäljare. TIN Ny Teknik marknadsförs och distribueras i Sverige, Norge och Finland. TIN World Tech marknadsförs och distribueras i Sverige.
          </small>

    </p>
</template>

<script>
import Language from '@/components/mixins/Language'
export default {
  mixins: [Language]
}
</script>