<script>
export default {
    data(){
        return {
            ScrollTriggers: {},
            FluidTriggers: {},
            StickyTrigger: {}
            // Examples:
            // ScrollTriggers: {
            //     strokeani: {active: false, ref: 'genericTrigger', screenPos: 0.5, callback: function(){}}
            // }
            // FluidTriggers: {
            //     strokeani: {ref: 'genericTrigger', screenPos: 1}
            // }
            // StickyTrigger: {
            //     fadeani: {ref: 'genricTrigger', screenPos: 0}
            // }
        }
    },
    methods:{
        handleScroll(){
            const viewport_size = window.innerHeight;
            let element_pos = null;

            for (const [, trigger] of Object.entries(this.StickyTrigger)){
                trigger.screenPos = Math.min((this.$refs[trigger.ref].offsetTop - this.$refs[trigger.ref].parentElement.offsetTop) / (
                    this.$refs[trigger.ref].parentElement.clientHeight - this.$refs[trigger.ref].clientHeight
                ), 0.99);
                this.$refs[trigger.ref].style['animation-delay'] = `-${trigger.screenPos}s`
            }

            for (const [, trigger] of Object.entries(this.FluidTriggers)){
                try {
                    element_pos = this.$refs[trigger.ref].getBoundingClientRect().top;
                } catch(err) {
                    continue;
                }
                let screen_value = (element_pos - (trigger.stop * viewport_size)) / ((trigger.start-trigger.stop)*viewport_size);
                if (screen_value > 1) {screen_value = 1}
                if (screen_value <= 0.01) {screen_value = 0.01}
                trigger.screenPos = screen_value;
                this.$refs[trigger.ref].style['animation-delay'] = `-${1 - screen_value}s`
            }

            for(const [, trigger] of Object.entries(this.ScrollTriggers)){
                try {
                    element_pos = this.$refs[trigger.ref].getBoundingClientRect().top;
                } catch(err) {
                    continue;
                }
                if(element_pos < viewport_size * trigger.screenPos && !trigger.activated){
                    trigger.activated = true;
                    let delay = 0;
                    if(trigger.callback instanceof Function){
                        trigger.callback();
                    }
                    if(trigger.randomDelay){
                        delay = Math.floor(Math.random() * trigger.randomDelay * 1000);
                    }
                    setTimeout(function(trigger){
                        trigger.active = true;
                    }, delay, trigger);
                }
            }
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        for (const [, trigger] of Object.entries(this.FluidTriggers)){
            if(!trigger.start){
                trigger.start = 1;
            }
            if(!trigger.stop){
                trigger.stop = 0;
            }
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
}
</script>