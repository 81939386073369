<template>
    <!--<div class="scroll-holder">
        <div class="scroll-card" ref="scrollcard2">-->
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 p-3">
                        <div class="px-4 px-md-5 pt-5 h-100 d-flex flex-column about-card justify-content-between">
                            <div>
                                <h1 class="text-tin-dark text-center mb-4 position-relative">
                                    <span>Vinnare i en digital värld</span>
                                </h1>
                                <p class="mx-auto text-center px-0 px-lg-5">
                                    <span>Vi har startat TIN Ny Teknik med tron att digitala tillgångar kommer bli mer värdefulla i framtiden. Vi tycker en aktiefond med innovativa bolag bör utgöra kärnan snarare än kryddan i ett långsiktigt sparande.</span>
                                </p>
                            </div>
                            <div class="hand-outer" ref="joyconani" v-bind:class="{activated: ScrollTriggers.joyconani.active}">
                                <div class="con_left"></div>
                                <div class="con_right"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 p-3">
                        <div class="px-4 px-md-5 py-5 about-card h-100 d-flex flex-column justify-content-between">
                            <div>
                                <h1 class="text-tin-dark text-center mb-4 position-relative">
                                    <!--<span class="fade-heading-2" ref="fadehead">Aktiv</span> förvaltning<br><span class="whitespace-nowrap"> i världsklass</span>-->
                                    Aktiv förvaltning i världsklass
                                </h1>
                                <flip-card :length="15">
                                    <p class="text-center mb-4 px-0 px-lg-5">
                                        Vi har startat aktiefonden TIN Ny Teknik med tron att digitala tillgångar kommer bli mer värdefulla i framtiden. 
                                    </p>
                                    <p class="text-center mb-4 px-0 px-lg-5">
                                        Carl och Erik har jobbat som ett team i över tio år.
                                    </p>
                                </flip-card>
                            </div>
                            <div class="d-flex justify-content-center flex-wrap mb-1">
                                <div class="text-center">
                                    <RoundImage src='/img/photos/carl.jpg' class="shadow-sm mx-4 mb-2"/>
                                    <h5 class="text-tin-dark mb-1">Carl Armfelt</h5>
                                    <h6 class="text-tin-light-blue">Aktieförvaltare</h6>
                                    <p class="mw-100">
                                    
                                    </p>
                                </div>
                                <div class="text-center">
                                    <RoundImage src='/img/photos/erik.jpg' class="shadow-sm mx-4 mb-2" />
                                    <h5 class="text-tin-dark mb-1">Erik Sprinchorn</h5>
                                    <h6 class="text-tin-light-blue">Aktieförvaltare</h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 p-3">
                        <div class="about-card px-4 px-md-5 py-5 h-100">
                            <h1 class="text-tin-dark mb-4 position-relative text-center hero-heading-2">
                                Följ med på vår resa
                            </h1>
                            <MailSignup class="mx-auto mw-500"/>
                            <p class="mw-500 mx-auto text-center">
                                Månadsbrevet innehåller våra insikter kring aktiemarknaden och nya händelser i våra fonder.
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        <!--</div>
    </div>-->
</template>

<script>
import RoundImage from '@/components/modules/RoundImage';
import DefaultModule from '@/components/mixins/DefaultModule';
import Language from '@/components/mixins/Language';
import ScrollTriggerVue from '../mixins/ScrollTrigger.vue';
import FlipCard from '../modules/FlipCard.vue';

export default {
    mixins:[DefaultModule, ScrollTriggerVue, Language],
    data(){
        return {
            FluidTriggers:{
                fadehead: {ref: 'fadehead', screenPos: 1}
            },
            ScrollTriggers:{
                joyconani: {active: false, ref: 'joyconani', screenPos: 0.5, callback: function(){}}
            }
        }
    },
    components:{
        RoundImage,
        FlipCard
    },
}
</script>

<style scoped>
.about-card{
    border-radius: 16px;
    background: #fff;
}
.hand-outer{
    width: 90%;
    margin: 0 auto;
    height: 200px;
    overflow: hidden;
    padding-top: 30px;
    position: relative;
    display: flex;
}

.hand-outer.activated > .con_left{
    background-position: top right;
    opacity: 1;
}
.hand-outer.activated > .con_right{
    background-position: top left;
    opacity: 1;
}

.con_right{
    background-image: url('/img/graphics/con_right.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    height: 300px;
    flex-grow: 1;
    transition: 0.45s all ease;
    margin-left: 10px;
    opacity: 0;
}
.con_left{
    background-image: url('/img/graphics/con_left.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    height: 300px;
    flex-grow: 1;
    transition: 0.45s all ease;
    margin-right: 10px;
    opacity: 0;
}
.container{
    max-width: 100%;
}
.scroll-holder{
    height: 1500px;
}
.scroll-card{
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
} 
</style>