<template>
    <div id="mc_embed_signup">
        <form 
            action="https://tinfonder.us20.list-manage.com/subscribe/post?u=b6f47194e55208f15915f8f87&amp;id=7df7175a37" 
            method="post" 
            id="mc-embedded-subscribe-form" 
            name="mc-embedded-subscribe-form" 
            class="validate" 
            target="_blank" 
            novalidate
            onsubmit="onSubmit()"
            >
            <div class="input-group mb-3">
                <input type="hidden" :value="href" name="SIGNUP">
                <input type="email" value="" name="EMAIL" class="required email form-control" id="mce-EMAIL" placeholder="E-postadress">
                <div class="input-group-append">
                <input type="submit" value="Prenumerera" name="subscribe" id="nws-oth-visit" class="button btn btn-outline-secondary">
                </div>
            </div>
            <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display:none"></div>
                <div class="response" id="mce-success-response" style="display:none"></div>
            </div>
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b6f47194e55208f15915f8f87_7df7175a37" tabindex="-1" value=""></div>
        
        </form>
    </div>
</template>

<script>

import Language from '@/components/mixins/Language';

export default {
    components:{

    },
    mixins:[Language],
    data() {
        return {
            MailChimpUrl: 'https://tinfonder.us20.list-manage.com/subscribe/post-json',
            CaptchaUrl: 'https://tinfonder.us20.list-manage.com/subscribe/post',
            UserId: 'b6f47194e55208f15915f8f87',
            ListId: '7df7175a37'
        }
    },
    computed:{
        href(){
            return window.location.href;
        }
    },
    methods:{
        onError(err){
            if(err=='captcha'){
                window.open(this.CaptchaUrl + `?EMAIL=${this.$refs.mailchimpmodule.email}&u=${this.UserId}&id=${this.ListId}`)
            }
        },
        onSubmit(){
            fetch('https://tinfonder.se/api/convert/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'subscribe',
                })
            })
        }
    },
}
</script>

<style scoped>
    #nws-oth-visit{

    }
    #email-input{

    }
</style>