<script>
import MailSignup from '@/components/modules/MailSignup'
export default {
  name: 'DefaultModule',
  components:{
    MailSignup
  },
  data(){
    return {
      fallbackValues:{
        'primary-video-desc': 'Den 12 december höll vi en livestream där vi pratade gaming, teknik och hälsa samt svarande på våra andelsägares frågor.',
        'title': 'TIN Fonder'
      }
    }
  },
}
</script>


<style scoped>

</style>
