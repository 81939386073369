<script>
import FullImage from '@/components/modules/FullImage'
import YouTubeVideo from '@/components/modules/YouTubeVideo'
import Button from '@/components/modules/Button'
import ContentSection from '@/components/modules/ContentSection'
import ContentSectionNarrow from '@/components/modules/ContentSectionNarrow'
import ImageWithText from '@/components/modules/ImageWithText'
import RoundImage from '@/components/modules/RoundImage'
import RoundBallText from '@/components/modules/RoundBallText'
import DownArrow from '@/components/modules/DownArrow'
import MailSignup from '@/components/modules/MailSignup'
import BuyFund from '@/components/sections/BuyFund'
import Footer from '@/components/sections/Footer'
import AboutTin from '@/components/sections/AboutTin'
import OurBullets from '@/components/sections/OurBullets'
import Sectors from '@/components/sections/Sectors'
import Newsletter from '@/components/sections/Newsletter'
import Development from '@/components/sections/Development'
import BuyFundLite from '@/components/sections/BuyFundLite'

export default {
  name: 'DefaultPage',
  components:{
    BuyFundLite,
    ContentSectionNarrow,
    FullImage,
    YouTubeVideo,
    Button,
    ContentSection,
    ImageWithText,
    RoundImage,
    RoundBallText,
    DownArrow,
    BuyFund,
    Footer,
    AboutTin,
    MailSignup,
    OurBullets,
    Sectors,
    Newsletter,
    Development
  },
  data(){
    return {
      apiUrl: `/public/landing/${this.$route.params.shortname}/`,
      fallbackValues:{
        'primary-video-desc': 'Den 12 december höll vi en livestream där vi pratade gaming, teknik och hälsa samt svarande på våra andelsägares frågor.',
        'title': 'TIN Fonder'
      }
    }
  },
  computed:{

  },
  methods:{

  },
  created(){

  }
}
</script>


<style scoped>

</style>
