<template>
    <div>
      <h1 class="text-tin-dark text-center mb-4">Våra fördelar</h1>
      <p class="mx-auto mw-600 mb-4 text-center">
        Med ett starkt varumärke och kontaktnät kan vi delta i ankarinvesteringar och påverka våra portföljbolag i rätt riktning.
      </p>
      <div class="d-flex justify-content-center flex-wrap">
        <round-ball-text style="width:160px; height:160px;" class="m-2"><h5>Ankar- <br>investeringar</h5></round-ball-text>
        <round-ball-text style="width:160px; height:160px;" class="m-2"><h5>TIN Analytics</h5></round-ball-text>
        <round-ball-text style="width:160px; height:160px;" class="m-2"><h5>Påverkan</h5></round-ball-text>
        <round-ball-text style="width:160px; height:160px;" class="m-2 d-none d-md-flex"><h5>Digitalt fokus</h5></round-ball-text>
      </div>
    </div>
</template>

<script>
import RoundBallText from '@/components/modules/RoundBallText';

export default {
    components:{
        RoundBallText
    }
}
</script>

<style>

  
</style>