<script>
export default {
    methods:{
        getLang(){
            let lang = this.$route.query["lang"];
            if(!(lang == 'se' || lang== 'no')){
                lang = 'se'
            }
            return lang;
        },
    },
    data(){
        return {
            content_text:{
                teknisk_innovation_heading:{
                    'no': 'Teknisk innovasjon',
                    'se': 'Teknisk innovation',
                },
                teknisk_innovation_text:{
                    'no': 'TIN Ny Teknikk er et aksjefond som investerer i teknikk, helse og digitale varemerker.',
                    'se': 'TIN Ny Teknik är en aktiefond som investerar i teknik, hälsa och digitala varumärken.'
                },
                buy_fund_heading_1:{
                    'no': 'Et unikt',
                    'se': 'En unik',
                },
                buy_fund_heading_2:{
                    'no': 'teknologifond',
                    'se': 'teknologifond',
                },
                subscribe:{
                    'no': 'Meld deg på',
                    'se': 'Prenumerera'
                },
                las_mer:{
                    'se': 'Läs mer',
                    'no': 'Les mer',
                },
                faktablad:{
                    'se': 'Mer information inklusive fondens avgift finns i fondens faktablad och prospekt. Dessa finns att hämta på <a href="https://tinfonder.se/dokument/">tinfonder.se</a>',
                    'no': 'Mer informasjon, inkludert fondets honorar, finner du i fondets faktaark og prospekt. Disse kan lastes ned fra <a href="https://tinfonder.se/dokument/">tinfonder.se</a>'
                },
                disclaimer:{
                    'se': 'Historisk avkastning är inte någon garanti för framtida avkastning. De pengar du investerar i fonder kan både öka och minska i värde och det är inte säkert att du får tillbaka hela det insatta kapitalet. Faktablad, informationsbroschyrer och fondbestämmelser för TIN Fonders fonder finns att hämta på vår hemsida eller hos någon av våra återförsäljare. TIN Ny Teknik marknadsförs och distribueras i Sverige, Norge och Finland. TIN World Tech marknadsförs och distribueras i Sverige.',
                    'no': 'Historisk avkastning er ingen garanti for framtidig avkastning. Framtidig avkastning vil bl.a. avhenge av markedsutviklingen, forvalters dyktighet, verdipapirfondets risiko, samt kostnader ved tegning, forvaltning og innløsning. Avkastningen kan bli negativ som følge av kurstap. Faktaark, informasjonsbrosjyrer og fondregler for TIN Fonder sine midler kan lastes ned fra nettstedet vårt eller fra en av våre forhandlere.'
                },
                vinnare: {
                    'se': 'Vinnare i en digital värld',
                    'no': 'Vinner av en<br> digital verden'
                },
                vara_bolag:{
                    'se': 'Några av våra bolag',
                    'no': 'Noen av selskapene våre'
                },
                hur_har_det_gatt:{
                    'se': 'Hur har det gått?',
                    'no': 'Hvordan gikk det?'
                },
                fokus_pa_innovation:{
                    'se': 'Fokus på innovation',
                    'no': 'Fokus på innovasjon'
                },
                forvaltning_heading:{
                    'se': 'Aktiv förvaltning i världsklass',
                    'no': 'Aktiv fondsforvaltning i verdensklasse'
                },
                forvaltning_text1:{
                    'se': 'Vi har startat aktiefonden TIN Ny Teknik med tron att digitala tillgångar kommer bli mer värdefulla i framtiden.',
                    'no': 'Vi har startet aksjefondet TIN Ny Teknik med troen på at digitale eiendeler vil være mer verdifulle i fremtiden.'
                },
                forvaltning_text2:{
                    'se': 'Carl och Erik har jobbat som ett team i över tio år.',
                    'no': 'Carl og Erik har jobbet som et team i over ti år og har alltid fokusert på digitale selskaper.'
                },
                aktie_forvaltare: {
                    'se': 'Aktieförvaltare',
                    'no': 'Forvalter'
                },
                email_text:{
                    'se': 'Månadsbrevet innehåller våra insikter kring aktiemarknaden och nya händelser i våra fonder.',
                    'no': 'Meld deg på nyhetsbrevet og få månedlig innsikt i aksjemarkedet og informasjon om nye hendelser i fondene våre.'
                },
                email_heading:{
                    'se': 'Följ med på vår resa',
                    'no': 'Bli med på reisen'
                },
                sectors_heading:{
                    'se': 'Vi investerar i Framtiden',
                    'no': 'Vi investerer i fremtiden'
                },
                digitala_varumarken_heading:{
                    'se': 'Digitala varumärken',
                    'no': 'Digitale merker'
                },
                digitala_varumarken_text:{
                    'se': 'Fortnite, Minecraft och Battlefield är globala exempel på hur digitala varumärken och digitala världar kommer bli än viktigare i framtiden.',
                    'no': 'Fortnite, Minecraft og Battlefield er globale eksempler på hvordan digitale merker og digitale verdener vil bli enda viktigere i fremtiden.'
                },
                mjukvara_heading:{
                    'se': 'Mjukvara',
                    'no': 'Programvare'
                },
                mjukvara_text:{
                    'se': 'Mjukvara äter världen. Vi försöker hitta bolag som är nichevinnare eller tack vare starka produkter eller tjänster kan uppvisa hög tillväxt och hög lönsamhet över tid.',
                    'no': 'Programvare spiser verden. Vi prøver å finne selskaper som er nisjevinnere eller, takket være sterke produkter eller tjenester, kan vise høy vekst og høy lønnsomhet over tid.'
                },
                halsa_heading:{
                    'se': 'Hälsa',
                    'no': 'Helse'
                },
                halsa_text:{
                    'se': 'Medicinteknik är ett måste i ett samhälle där vi vill leva längre och friskare. Vi försöker hitta bolag som genom ett patent eller teknisk innovation kan uppvisa både hög tillväxt och hög lönsamhet.',
                    'no': 'Medisinsk teknologi er et must i et samfunn der vi ønsker å leve lenger og sunnere. Vi prøver å finne selskaper som gjennom patent eller teknisk innovasjon kan vise til både høy vekst og høy lønnsomhet.'
                },
                teknikfond:{
                    'se': 'teknikfond',
                    'no': 'teknologifond'
                },
                kunder_heading:{
                    'se': 'Våra kunder',
                    'no': 'Våre kunder'
                },
                kunder_text:{
                    'se': 'Över 350 000 privatpersoner har investerat i våra fonder och största kundgrupp är åldern 18-30 år.',
                    'no': 'Over 350 000 privatpersoner har investert i fondene våre, og den største kundegruppen er i alderen 18-30.'
                },
                digitalt_heading:{
                    'se': 'Digitalt fokus',
                    'no': 'Digitalt fokus'
                },
                digitalt_text:{
                    'se': 'Vi lägger vårt fokus på digitala snabbväxande bolag.',
                    'no': 'Vi fokuserer på digitale raskt voksende selskaper.'
                },
                analytics_heading:{
                    'se': 'TIN Analytics',
                    'no': 'TIN Analytics'
                },
                analytics_text:{
                    'se': 'Vi samlar in över 600 000 datapunkter om våra innehav varje dag för att kunna följa deras utveckling bättre.',
                    'no': 'Vi samler inn over 600 000 datapunkter om våre beholdninger hver dag for å bedre følge utviklingen.'
                },
                pavarkan_heading:{
                    'se': 'Påverkan',
                    'no': 'Innvirkning'
                },
                pavarkan_text:{
                    'se': 'Vi är aktiva ägare i fondens innehav och vill påverka bolagen att fortsätta i rätt riktning.',
                    'no': 'Vi er aktive eiere i fondets beholdning og ønsker å påvirke selskapene til å fortsette i riktig retning.'
                },
                innovation_heading:{
                    'se': 'Teknisk innovasjon',
                    'no': 'Teknisk innovation'
                },
                innovation_text:{
                    'se': 'TIN Ny Teknik är en aktiefond som investerar i teknik, hälsa och digitala varumärken.',
                    'no': 'TIN Ny Teknik er et aksjefond som investerer i teknologi, helse og digitale merker.'
                },
                buy_sub_heading:{
                    'se': 'Följ med oss på fondens resa in i framtiden.',
                    'no': 'Bli med oss ​​på fondets reise inn i fremtiden.'
                },
                digitalt_fokus:{
                    'se': '',
                    'no': ''
                }
            }
        }
    }
}
</script>
