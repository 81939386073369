import VueRouter from 'vue-router'
import Landing1 from '@/components/pages/Landing1.vue'

const routes = [
    {
        path: '/tin',
        name: 'Root',
        component: Landing1,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

export default router;