<template>
    <img width='116s' height='116' :src="src">
</template>

<script>
export default {
    name: 'RoundImage',
    props: {
        src: String
    }
}
</script>

<style scoped>
    img{
        display:block;
        border-radius: 100%;
        object-fit: cover;
        image-rendering: auto;
    }
</style>